import { grayColor } from "assets/jss/material-kit-pro-react.js";

const style = {
  defaultFont: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
  },
  container: {
    direction: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  list: {
    direction: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  item: {
    padding: "5px 5px 5px",
  },
  button: {
    padding: "10px 5px 10px",
  },
  text: {
    padding: "10px 25px 10px",
    color: grayColor[0],
  },
};

export default style;
