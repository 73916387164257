import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const style = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "70px 0 30px",
  },
  iconWrapper: {
    float: "left",
    // marginTop: "24px",
    marginRight: "10px",
  },
  primary: {
    color: primaryColor[0],
  },
  warning: {
    color: warningColor[0],
  },
  danger: {
    color: dangerColor[0],
  },
  success: {
    color: successColor[0],
  },
  info: {
    color: infoColor[0],
  },
  rose: {
    color: roseColor[0],
  },
  gray: {
    color: grayColor[0],
  },
  icon: {
    width: "3.25rem",
    height: "3.25rem",
    fontSize: "3.25rem",
  },
  descriptionWrapper: {
    color: grayColor[0],
    overflow: "hidden",
  },
  title: {
    fontSize: "24px",
    lineHeight: 1.0,
    fontWeight: 300,
    color: "#000000",
    marginBotton: "0.5rem",
    fontFamily: "inherit",
    marginTop: 0,
    display: "block",
    // marginBlockStart: "1em",
    // marginBlockEnd: "1em",
    // marginInlineStart: "0px",
    // marginInlineEnd: "0px",
  },
  description: {
    color: grayColor[0],
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: grayColor[0],
      fontSize: "14px",
    },
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
};

export default style;
