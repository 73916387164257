import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import style from "./style.js";

import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  title: {
    letterSpacing: "unset",
    "&,& a": {
      fontFamily:
        '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: 400,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "32px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent",
      },
    },
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);
//<img src={logo} alt="Blue Marlin Arcade" />
function BrandLogo() {
  const classes = useStyles();

  return <div className={classes.title}>Blue Marlin Arcade</div>;
}
export default BrandLogo;
