/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// react component for creating beautiful carousel
import Carousel from "react-slick";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// sections for this page
import SectionDescription from "./Sections/SectionDescription";
import SectionRooms from "./Sections/SectionRooms";
import SectionParallax from "./Sections/SectionParallax";
import SectionReviews from "./Sections/SectionReviews";

// custom components
import Header from "_ext/components/Header/Header";
import HeaderLinks from "_ext/components/Header/HeaderLinks";
import LearnMoreButton from "_ext/components/Buttons/LearnMoreButton";
import BrandLogo from "_ext/components/BrandLogo/BrandLogo";
import SocialShare from "_ext/components/SocialShare/SocialShare";

//import style from "assets/jss/material-kit-pro-react/views/presentationStyle";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle";
import componentStyle from "assets/jss/material-kit-pro-react/views/componentsStyle.js";
import style from "./style";

import combineStyles from "_ext/services/combineStyles";
import ParallaxBanner from "_ext/components/Parallax/ParallaxBanner";

const styles = (theme) => ({
  page: {
    backgroundColor: "#6DB3F2",
  },
  title: {
    color: "#FFFFFF",
    textTransform: "uppercase",
    letterSpacing: ".1em",
    display: "inline-block",
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    marginRight: "1rem",
    fontSize: "1.25rem",
    lineHeight: "inherit",
    whiteSpace: "nowrap",
    textDecoraton: "none",
    backgroundColor: "transparent",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 300,
  },
  center: {
    margin: 0,
    position: "absolute",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  brandContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "70%",
  },
  mybrand: {
    fontSize: "8vw",
    color: "#FFFFFF",
    fontWeight: "300",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  parallaxContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "90%",
  },
  parallaxText: {
    fontSize: "8vw",
    marginBottom: "20px",
    lineHeight: "1.1",
    color: "#FFFFFF",
    fontWeight: "300",
    fontFamily: "inherit",
    marginTop: "0",
  },
  // welcome: {
  //   marginBottom: "30px",
  //   color: "#fff",
  //   fontSize: "24px",
  //   textTransform: "uppercase",
  //   letterSpacing: ".1em",
  //   position: "relative",
  //   display: "inline-block",
  //   fontWeight: "300",
  // },
});

const _styles1 = combineStyles(styles, componentStyle);
const _styles2 = combineStyles(_styles1, footerStyle);
const useStyles = makeStyles(combineStyles(_styles1, style));

export default function HomePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <Header
        brand={<BrandLogo />}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />

      <Carousel {...settings}>
        <ParallaxBanner
          image={require("_ext/assets/img/bg_bluemarlinarcade_sm.png")}
          className={classes.parallax + " " + classes.page}
        />
        <ParallaxBanner
          image={require("_ext/assets/img/bg_house_1_2400x1600_web.jpeg")}
          className={classes.parallax + " " + classes.page}
        >
          <div className={classes.container + " " + classes.center}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandContainer}>
                  <h3 className={classes.welcome}>Luxurious Experience</h3>
                  <h1 className={classes.mybrand}>A Beautiful Custom Home</h1>
                  <div style={{ height: "50px" }} />
                  <LearnMoreButton link="/" color="rose" target="_self" />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </ParallaxBanner>
        <ParallaxBanner
          image={require("_ext/assets/img/bg_arcade_2_2400x1600_web.jpeg")}
          className={classes.parallax + " " + classes.page}
        >
          <div className={classes.container + " " + classes.center}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandContainer}>
                  <h3 className={classes.welcome}>Entertainment Paradise</h3>
                  <h1 className={classes.mybrand}>Your Personal Arcade</h1>
                  <div style={{ height: "50px" }} />
                  <LearnMoreButton link="/arcade" color="rose" target="_self" />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </ParallaxBanner>
        <ParallaxBanner
          image={require("_ext/assets/img/bg_beach_1_2400x1600.jpeg")}
          className={classes.parallax + " " + classes.page}
        >
          <div className={classes.container + " " + classes.center}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandContainer}>
                  <h3 className={classes.welcome}>Ocean Views</h3>
                  <h1 className={classes.mybrand}>Convenient Beach Access</h1>
                  <div style={{ height: "50px" }} />
                  <LearnMoreButton link="/" color="rose" target="_self" />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </ParallaxBanner>
      </Carousel>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionRooms />
        <SectionParallax>
          <div
            className={classes.center}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginRight: "auto",
              marginLeft: "auto",
              width: "100%",
            }}
          >
            <GridContainer>
              <GridItem>
                <div className={classes.parallaxContainer}>
                  <h2 className={classes.parallaxText}>
                    Quality accommodation that exceeds the expectations
                  </h2>
                  <div style={{ height: "50px" }} />
                  <LearnMoreButton link="/" color="rose" target="_self" />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </SectionParallax>
        <SectionReviews />
      </div>
      <Footer
        theme="transparent"
        content={
          <div className={classes.pullCenter}>
            <SocialShare
              config={{
                url: "https://www.bluemarlinarcade.com",
                quote:
                  "The Blue Marlin Arcade is a beautiful, spacious, custom home with your own Personal Arcade!!  This is a MUST SEE!",
              }}
            ></SocialShare>
          </div>
        }
      />
    </div>
  );
}
