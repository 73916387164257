import {} from "assets/jss/material-kit-pro-react";

const style = {
  // title: {
  //   letterSpacing: "unset",
  //   "&,& a": {
  //     ...defaultFont,
  //     minWidth: "unset",
  //     lineHeight: "30px",
  //     fontSize: "18px",
  //     borderRadius: "3px",
  //     textTransform: "none",
  //     whiteSpace: "nowrap",
  //     color: "inherit",
  //     "&:hover,&:focus": {
  //       color: "inherit",
  //       background: "transparent",
  //     },
  //   },
  // },
};

export default style;
