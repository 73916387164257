import {
  container,
  section,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container: {
    ...container,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  description: {
    color: grayColor[3],
    textAlign: "left",
    width: "100%",
    fontSize: "18px",
    lineHeight: "1.5",
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em",
    },
    backgroundColor: "#FAFAFA",
  },
  headingContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    marginLeft: "5%",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "90%",
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: ".1em",
    marginBottom: "30px",
    color: "#999999",
    fontSize: "24px",
    position: "relative",
    display: "inline-block",
    fontWeight: "300",
    "&::after": {
      width: "80px",
      height: "2px",
      background: "#999999",
      position: "absolute",
      left: "110%",
      top: "50%",
      webkitTransform: "translateY(-50%)",
      msTransform: "translateY(-50%)",
      transform: "translateY(-50%)",
      content: '""',
    },
  },
  shortDescription: {
    fontSize: "48px",
    color: "#000000",
    fontWeight: "300",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  features: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0px",
    marginLeft: "3%",
    boxSizing: "border-box",
    display: "block",
    width: "90%",

    textAlign: "left !important",
    paddingTop: "30px",
    paddingBottom: "0px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em",
    },
  },
});

export default style;
