import {
  container,
  main,
  mainRaised,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const componentsStyle = (theme) => ({
  main,
  mainRaised,
  container,
  mlAuto,
  center: {
    margin: 0,
    position: "absolute",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  brandContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "70%",
    paddingLeft: "85px",
    //paddingLeft: "8%",
  },
  brandSubTitle: {
    fontSize: "8vw",
    color: "#FFFFFF",
    fontWeight: "300",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  brandTitle: {
    marginBottom: "30px",
    color: "#fff",
    fontSize: "24px",
    textTransform: "uppercase",
    letterSpacing: ".1em",
    position: "relative",
    display: "inline-block",
    fontWeight: "300",
  },
});

export default componentsStyle;
