import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import blueMarlinArcadeImage from "_ext/assets/img/bg_bluemarlinarcade.svg";
import rush2049Image from "_ext/assets/img/arcade/rush2049.png";
import metallicaPinballImage from "_ext/assets/img/arcade/metallica_pinball.png";
import dialedInPinballImage from "_ext/assets/img/arcade/dialedin_pinball.png";
import goldenTeeGolfImage from "_ext/assets/img/arcade/goldenteegolf_arcade_sm.png";
import blazingAngelsImage from "_ext/assets/img/arcade/blazingangels_arcade_transparent.png";
import maxForceImage from "_ext/assets/img/arcade/maxforce_arcade.png";
import pacmanMulticadeImage from "_ext/assets/img/arcade/pacman_arcade.png";
import tableMulticadeImage from "_ext/assets/img/arcade/table_arcade.png";
import airHockeyImage from "_ext/assets/img/arcade/airhockey.png";

import style from "./overviewStyle";

import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  page: {
    backgroundColor: "#6DB3F2",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionComponents() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={5} lg={5} sm={12} xs={12}>
            <div className={classes.headingContainer}>
              <h3 className={classes.title}>Arcade</h3>
              <h1 className={classes.shortDescription}>Blast from the Past</h1>
              <div style={{ height: "40px" }} />
              <p className={classes.description}>
                The Blue Marlin Arcade DOES NOT LACK ANYTHING when it comes to
                ENTERTAINMENT. The whole downstairs level is thoughtfully
                designed for Entertainment. The big open floor plan of this home
                makes it an ideal space for families, large groups, and various
                gatherings.
              </p>
              <br />
              <p className={classes.description}>
                This home is like no other when it comes to ENTERTAINMENT,
                making it fun for all ages! Our Game Room is the ideal
                "Entertainment Paradise" with foosball, air hockey, books,
                games, toys, wii, and an ARCADE WORLD Just added in JULY 2020.
                Currently offering 8 ARCADE MACHINES including a sit down ms.
                pacman arcade loaded with 80 classics, Golden Tee Golf, Blazing
                Angels (combat/flight), Maximum Force (stand up shooting game),
                Pinball, 2 side-by-side Rush Hour Racing, and a stand up
                multi-game arcade. Guaranteed to provide hours of FUN, all on
                FREE PLAY! (no quarters needed)
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                src={blueMarlinArcadeImage}
                alt="Blue Marlin Arcade"
                className={classes.componentsBackground}
              />
              {/* <div className={classes.imageContainer}>
                <img
                  src={rush2049Image}
                  alt="Rush 2049"
                  className={classes.rush2049}
                />
                <img
                  src={metallicaPinballImage}
                  alt="Metallica Pinball"
                  className={classes.metallicaPinball}
                />
                <img
                  src={dialedInPinballImage}
                  alt="Dialed-In Pinball"
                  className={classes.dialedInPinball}
                />
                <img
                  src={goldenTeeGolfImage}
                  alt="Golden Tee Golf 2008"
                  className={classes.goldenTeeGolfImage}
                />
                <img
                  src={blazingAngelsImage}
                  alt="Blazing Angels"
                  className={classes.blazingAngelsImage}
                />
                <img
                  src={maxForceImage}
                  alt="Maximum Force"
                  className={classes.maxForceImage}
                />
                <img
                  src={pacmanMulticadeImage}
                  alt="Pac-Man Multicade"
                  className={classes.pacmanMulticadeImage}
                />
                <img
                  src={tableMulticadeImage}
                  alt="60 in 1 Table Multicade"
                  className={classes.tableMulticadeImage}
                />
                <img
                  src={airHockeyImage}
                  alt="Air Hockey"
                  className={classes.airHockeyImage}
                />
              </div> */}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
