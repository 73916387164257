import React from "react";

function TrailingHR(props) {
  const { name, color } = props;
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: [
          `.${name}:after {`,
          "  width: 80px;",
          "  height: 2px;",
          `  background: ${color};`,
          "  position: absolute;",
          "  left: 120%;",
          "  top: 50%;",
          "  -webkit-transform: translateY(-50%);",
          "  -ms-transform: translateY(-50%);",
          "  transform: translateY(-50%);",
          '  content: ""; }',
        ].join("\n"),
      }}
    ></style>
  );
}
export default TrailingHR;
