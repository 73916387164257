import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import style from "./imageDetailCardStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
    display: "block",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "50%",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function ImageCard(props) {
  const { images, title, content, height } = props;

  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  function imageCarousel() {
    return images.map((img) => {
      return (
        <img
          component="img"
          alt={title}
          height={height ? height : "600"}
          src={img.image}
          key={img.name}
        />
      );
    });
  }

  return (
    <Paper elevation={3}>
      <div className={classes.root}>
        <GridContainer justify="center">
          <GridItem md={6} sm={12}>
            <Carousel {...settings}>{imageCarousel()}</Carousel>
          </GridItem>
          <GridItem md={6} sm={12}>
            <div className={classes.content} style={{ padding: "10% 7%" }}>
              <h2 className={classes.title}>{title}</h2>
              <div className={classes.content}>{content}</div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Paper>
  );
}
