import React from "react";
import Carousel from "react-slick";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// custom components
import ImageDetailCard from "_ext/components/Cards/ImageDetailCard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import style from "./detailStyle";

import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  page: {
    backgroundColor: "#6DB3F2",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionDetail() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const height = "600";
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={7} lg={7} sm={12} xs={12}>
            <div className={classes.headingContainer}>
              <h3 className={classes.title}>Our Collection</h3>
              <h1 className={classes.shortDescription}>
                Something for Everyone
              </h1>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem md={12} sm={12}>
            <Carousel {...settings}>
              <ImageDetailCard
                title="Air Hockey by Shelti"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/airhockey_1.png"),
                    name: "airhockey",
                  },
                ]}
                content={
                  <div>
                    Mark Robbins, owner of Gold Standard Games, has been
                    designing air hockey tables for over 30 years. Competitive
                    players demand tables that provide superior speed and
                    bounce, with pucks that float smoothly and stay on the
                    table. Endorsed by Air Hockey Champions, this is the
                    ultimate home air hockey table.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Dialed-in Pinball by Jersey Jack"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/dialedin_pinball.png"),
                    name: "dialedin_pinball",
                  },
                ]}
                content={
                  <div>
                    Ranked #17 out of the top 100 Pinball Games. Pat Lawlor’s
                    original concept and history-making game. The first pinball
                    machine to feature Bluetooth® connectivity, the ability to
                    unlock additional capabilities through a smartphone.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Metallica Pinball by Stern"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/metallica_pinball.png"),
                    name: "metallica_pinball",
                  },
                ]}
                content={
                  <div>
                    Ranked #11 out of the top 100 Pinball Games. The newest
                    addition to the iconic Stern collection of rock and roll
                    machines honors one of the world’s foremost rock bands.
                    Metallica’s notoriously fast tempos and aggressive
                    musicianship sets the tone for an exciting player
                    experience. Players will be able to bang their heads and
                    their flippers to 12 classic Metallica hits including
                    “Master of Puppets,” “One” and “Fade to Black.”
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Rush 2049 by Atari"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/rush2049.png"),
                    name: "rush2049",
                  },
                ]}
                content={
                  <div>
                    Atari Rush 2049 is futuristic driving game that takes place
                    in San Francisco. We have "Dual" Units to race side-by-side.
                    They are sit-down machines with force feedback steering
                    wheels, gear shifts, and three pedals (gas, brake, and
                    clutch). A telephone-like keypad to the right of the
                    steering wheel gives players the option of choosing a PIN
                    and allows them to earn points to unlock new cars and
                    tracks. Continue where you left off on your next visit to
                    the Blue Marlin Arcade.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Blazing Angels Squadron of WWII by Global VR"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/blazingangels_arcade.jpg"),
                    name: "blazingangels_arcade",
                  },
                ]}
                content={
                  <div>
                    Test your mettle as a fighter pilot in 6 WWII Campaigns
                    (easy, medium or hard difficulty) that include London,
                    Africa, Pearl Harbor, Midway, Rabaul and Ardennes. Beat a
                    medium or hard scenario and fly an amazing bonus level…
                    Berlin.
                    <br />
                    <br />
                    From the dogfights to clearly defined mission and
                    objectives, players will engage in combat action that will
                    keep them coming back again and again.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Golden Tee Golf 2008 by Golden Tee"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/goldenteegolf_arcade_2_sm.gif"),
                    name: "goldenteegolf_arcade_2_sm",
                  },
                ]}
                content={
                  <div>
                    Real-Time Competition. LIVE pits video golfers across the
                    world in head-to-head contests for PRIZES or GLORY. From the
                    comfort of their local taverns and restaurants, thousands of
                    players can PLAY LIVE and compete simultaneously while
                    scores are updated universally, hole-by-hole.
                    <br />
                    <br />
                    Golden Tee LIVE represents the future of video golf and
                    out-of-home video game experiences.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Maximum Force by Atari"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/maxforce_arcade_1.png"),
                    name: "maxforce_arcade",
                  },
                ]}
                content={
                  <div>
                    Maximum Force is a target shooting game for one or two
                    players that pits a task force against a terrorist group.
                    Take the bad guys out while avoiding innocent bystanders
                    (hitting innocents will damage your health). Maximum Force
                    features three pulse-pounding missions, bonus underwater
                    action! This game also uses Atari’s ever accurate "AccuAim”
                    optical targeting system.
                    <br />
                    <br />
                    Recently upgraded to include Area 51 and Maximum Force.
                  </div>
                }
                height={height}
              />
              <ImageDetailCard
                title="Pac-man Multicade"
                images={[
                  {
                    image: require("_ext/assets/img/arcade/pacman_arcade.png"),
                    name: "pacman_arcade",
                  },
                ]}
                content={<div>Pacman Multicate - original console.</div>}
                height={height}
              />{" "}
            </Carousel>
          </GridItem>
        </GridContainer>
        <div style={{ height: "500px" }} />
      </div>
    </div>
  );
}
