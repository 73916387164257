import React from "react";
import ReactDOM from "react-dom";
import App from "_ext/components/App";

ReactDOM.render(
  //  <React.StrictMode>
  <App />,
  //  </React.StrictMode>,
  document.getElementById("root")
);
