import {
  container,
  section,
  main,
  mainRaised,
  title,
  mlAuto,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  main,
  mainRaised,
  mlAuto,
  container: {
    ...container,
    paddingLeft: "5px",
    paddingRight: "5px",
  },

  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em",
    },
    backgroundColor: "#FAFAFA",
  },

  headingContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    marginLeft: "5%",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "90%",
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: ".1em",
    marginBottom: "30px",
    color: "#999999",
    fontSize: "24px",
    position: "relative",
    display: "inline-block",
    fontWeight: "300",
  },
  description: {
    color: grayColor[3],
    textAlign: "left",
    width: "100%",
    fontSize: "18px",
    lineHeight: "1.5",
  },

  shortDescription: {
    fontSize: "48px",
    color: "#000000",
    fontWeight: "300",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
});

export default style;
