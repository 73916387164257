import React from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Star from "@material-ui/icons/Star";

//import style from "./reviewCardStyle.js";
import combineStyles from "_ext/services/combineStyles";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    //    padding: theme.spacing(0, 1),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function ReviewCard(props) {
  const { title, description, name, stayed } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <h3>{title}</h3>
          <Star style={{ color: "#6DB3F2" }} />
          <Star style={{ color: "#6DB3F2" }} />
          <Star style={{ color: "#6DB3F2" }} />
          <Star style={{ color: "#6DB3F2" }} />
          <Star style={{ color: "#6DB3F2" }} />
          <h4>{description}</h4>
        </CardBody>
        <CardFooter>
          <div className={classes.authorWhite}>
            <span>{name}</span>
          </div>
          <div className={classes.authorWhite + " " + classes.mlAuto}>
            Stayed · {stayed}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
