import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui icons
import SportsEsports from "@material-ui/icons/SportsEsports";
import Hotel from "@material-ui/icons/Hotel";
import Kitchen from "@material-ui/icons/Kitchen";
import BeachAccess from "@material-ui/icons/BeachAccess";
import RoomService from "@material-ui/icons/RoomService";
import Security from "@material-ui/icons/Security";
import HotTub from "@material-ui/icons/HotTub";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// custom components
import FeatureCard from "_ext/components/Cards/FeatureCard";

import style from "./descriptionStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={12} sm={12}>
            <div className={classes.headingContainer}>
              <h3 className={classes.title}>Home</h3>
              <h1 className={classes.shortDescription}>Overview & Services</h1>
              <div style={{ height: "40px" }} />
              <p className={classes.description}>
                The BLUE MARLIN ARCADE is a beautiful 3,150 sq/ft custom home
                that was built in 2008 and recently renovated, in June of 2020,
                with all your vacation rental needs.
              </p>
              <br />
              <p className={classes.description}>
                This spacious beach home in NW Lincoln City, Oregon has ocean
                views and convenient beach access. You are literally only steps
                away from the NW 15TH Street Beach Access. Beach access is Drive
                Down & Wheel Chair accessible!!!
              </p>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={SportsEsports}
                iconColor={"info"}
                title={"Personal Arcade"}
                message={
                  'This home is like no other when it comes to entertainment, making it fun for all ages! Our Game Room is the ideal "Entertainment Paradise" with foosball, air hockey, books, games, toys, wii, and an ARCADE WORLD Just added in JULY 2020.  Arcades, Pinballs, and many more all set to Free-Play (NO quarters needed).'
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={Hotel}
                iconColor={"info"}
                title={"Luxury Bedrooms"}
                message={
                  "The Blue Marlin will fit all your needs with 3 bedrooms, almost ALL beds have Simmons Beautyrest pillow top mattresses(even the twin bunks!).  As of January 1, 2018, the maximum occupancy of this home will be 10 guests. This is per new Lincoln City regulations."
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={Kitchen}
                iconColor={"info"}
                title={"Full Kitchen / Dining"}
                message={
                  "Fully equipped spacious kitchen comes with high-end stainless steel appliances, Tile counter tops, over-sized island and bar that seats 4. Adjoining Dining Room has a beautiful wood table for seating up to 10 in a spacious dining area."
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={BeachAccess}
                iconColor={"info"}
                title={"Beach Access"}
                message={
                  "We truly are located in the perfect spot! Access is on the NW 15th Street ramp,(approximately 500 feet) Super close! This is the only access in Lincoln City that has an option of driving your car down onto the beach, making it very convenient for all ages. Beach Access is also Wheel-Chair accessible."
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={RoomService}
                iconColor={"info"}
                title={"Virtual Concierge"}
                message={
                  'We now also have 2 smart devices for Guest use. There is a Google Home Hub on the main floor and a Google mini in the game room. These are voice activated and ready to assist Guests just by saying "Hey Google" The smart devices can be used to turn on/off tvs, arcades, music, search local restaurants, check weather, and so on... all at the command of your voice!'
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={Security}
                iconColor={"info"}
                title={"Video Surveillance"}
                message={
                  "Two Video Surveillance cameras monitor the exterior of our home. One is a doorbell camera capturing the landing at the front door and the second one capturing the driveway. Both cameras only capture video, no audio. Any attempt at disabling, redirecting or obscuring the cameras is prohibited."
                }
              />
            </GridItem>
            <GridItem md={4} sm={6}>
              <FeatureCard
                icon={HotTub}
                iconColor={"info"}
                title={"Hot Tub"}
                message={
                  "The master suite has a sliding door that opens out the the 3rd floor deck with hot tub.  All bedrooms have access to the deck w/beach view and the hot tub.  Lincoln City Community Center has separate adult and kids pools. Great Place to hit for the Open Swim times. (Located 1/2 mile from the home) on 2150 NE Oar Place"
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
