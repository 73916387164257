import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";

import style from "./imageCardStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  root: {
    maxWidth: 500,
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function ImageCard(props) {
  const { images, title, description, content } = props;

  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  function imageCarousel() {
    return images.map((img) => {
      return (
        <CardMedia
          component="img"
          alt={title}
          height="340"
          image={img.image}
          title={title}
          key={img.name}
        />
      );
    });
  }

  return (
    <Card className={classes.root} raised>
      {/* <CardActionArea> */}
      <Carousel {...settings}>{imageCarousel()}</Carousel>
      <CardContent>
        <h3 className={classes.title}>{title}</h3>
        <Divider variant="fullWidth" />

        <div className={classes.description}> {description}</div>
        <Divider variant="fullWidth" />

        {content}
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}
