import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import style from "./featureCardStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    //    padding: theme.spacing(0, 1),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

//const message = `Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.`;

export default function FeatureCard(props) {
  const { message, title, iconColor, vertical } = props;

  const classes = useStyles();
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical,
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  let icon = null;
  switch (typeof props.icon) {
    case "string":
      icon = <Icon className={iconClasses}>{props.icon}</Icon>;
      break;
    default:
      icon = <props.icon className={iconClasses} />;
      break;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <div className={iconWrapper}>{icon}</div>
          </Grid>
          <Grid item xs>
            <h3 className={classes.title}>{title}</h3>
            <Typography
              style={{
                fontSize: "16px",
                display: "block",
                marginBlockStart: "1em",
                marginBlockEnd: "1em",
                marginInlineStart: "0px",
                marginInlineEnd: "0px",
                fontFamily: `"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                lineHeight: 1.8,
                fontWeight: 300,
              }}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
