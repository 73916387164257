import {
  container,
  section,
  main,
  mainRaised,
  title,
  mlAuto,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const reusableStylesForImgs = {
  position: "absolute",
  // boxShadow:
  //   "0 8px 10px 1px rgba(" +
  //   hexToRgb(blackColor) +
  //   ", 0.14), 0 3px 14px 2px rgba(" +
  //   hexToRgb(blackColor) +
  //   ", 0.12), 0 5px 5px -3px rgba(" +
  //   hexToRgb(blackColor) +
  //   ", 0.2)",
};

const style = (theme) => ({
  main,
  mainRaised,
  mlAuto,
  container: {
    ...container,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em",
    },
  },
  imageContainer: {
    height: "560px",
    position: "relative",
  },
  componentsBackground: {
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "850px!important",
      // maxHeight: "480px!important",
      marginTop: "-16vh",
      left: "-142px",
    },
    [theme.breakpoints.down(768)]: {
      // maxWidth: "350px!important",
      // maxHeight: "250px!important",
      width: "150%",
      marginTop: "-16vh",
      left: "-112px",
    },
    width: "auto",
    left: "-300px",
    top: 150,
    height: "120%",
    position: "absolute",
  },
  rush2049: {
    [theme.breakpoints.down("md")]: {
      top: "12%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "7%",
    },
    ...reusableStylesForImgs,
    left: "3%",
    top: "-3%",
    height: "125px",
    borderRadius: "4px",
    WebkitAnimation: "Floatingx 14s ease-in-out infinite",
  },
  metallicaPinball: {
    [theme.breakpoints.down("md")]: {
      top: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "75%",
    },
    ...reusableStylesForImgs,
    left: "75%",
    top: "75%",
    height: "125px",
    WebkitAnimation: "Floatingy 17s ease-in-out infinite",
  },
  dialedInPinball: {
    [theme.breakpoints.down("md")]: {
      top: "-2%",
      left: "55%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "-2%",
    },
    ...reusableStylesForImgs,
    left: "55%",
    top: "2%",
    height: "125px",
    borderRadius: "6px",
    WebkitAnimation: "Floatingy 18s ease-in-out infinite",
  },
  goldenTeeGolfImage: {
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
    ...reusableStylesForImgs,
    left: "65%",
    top: "35%",
    height: "125px",
    borderRadius: "2px",
    WebkitAnimation: "Floatingy 10s ease-in-out infinite",
  },
  blazingAngelsImage: {
    [theme.breakpoints.down("md")]: {
      left: "47%",
      top: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "47%",
      top: "95%",
    },
    ...reusableStylesForImgs,
    left: "47%",
    top: "95%",
    height: "125px",
    WebkitAnimation: "Floatingx 26s ease-in-out infinite",
  },
  maxForceImage: {
    [theme.breakpoints.down("md")]: {
      top: "90%",
    },
    ...reusableStylesForImgs,
    left: "0%",
    top: "90%",
    height: "125px",
    borderRadius: "3px",
    WebkitAnimation: "Floatingy 15s ease-in-out infinite",
  },
  pacmanMulticadeImage: {
    [theme.breakpoints.down("md")]: {
      top: "45%",
    },
    ...reusableStylesForImgs,
    left: "-15%",
    top: "45%",
    height: "125px",
    borderRadius: "3px",
    WebkitAnimation: "Floatingx 22s ease-in-out infinite",
  },
  tableMulticadeImage: {
    [theme.breakpoints.down("md")]: {
      top: "110%",
    },
    ...reusableStylesForImgs,
    left: "15%",
    top: "110%",
    height: "75px",
    borderRadius: "3px",
    WebkitAnimation: "Floatingx 22s ease-in-out infinite",
  },
  airHockeyImage: {
    [theme.breakpoints.down("md")]: {
      top: "25%",
    },
    ...reusableStylesForImgs,
    left: "-10%",
    top: "25%",
    height: "75px",
    borderRadius: "3px",
    WebkitAnimation: "Floatingy 16s ease-in-out infinite",
  },

  headingContainer: {
    lineHeight: "1.1",
    marginBottom: "50px",
    marginTop: "0",
    marginLeft: "5%",
    boxSizing: "border-box",
    display: "block",
    textAlign: "left",
    width: "90%",
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: ".1em",
    marginBottom: "30px",
    color: "#999999",
    fontSize: "24px",
    position: "relative",
    display: "inline-block",
    fontWeight: "300",
  },
  description: {
    color: grayColor[3],
    textAlign: "left",
    width: "100%",
    fontSize: "18px",
    lineHeight: "1.5",
  },

  shortDescription: {
    fontSize: "48px",
    color: "#000000",
    fontWeight: "300",
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
});

export default style;
