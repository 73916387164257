import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button";

import style from "./style.js";

import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  root: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "#FFFFFF",
    border: 1,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "#666666",
    height: 56,
    padding: "0 30px",
    fontSize: "24px",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

function LearnMoreButton(props) {
  const classes = useStyles();

  return (
    <Button
      href={props.link}
      className={classes.root}
      //onClick={(e) => e.preventDefault()}
      color={props.color ? props.color : "white"}
      //style={{ backgroundColor: "transparent" }}
      target={props.target}
      variant="contained"
    >
      Learn More
    </Button>
  );
}
export default LearnMoreButton;
