import React from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  PinterestShareButton,
  PinterestIcon,
  TumblrShareButton,
  TumblrIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import style from "./style";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({});

const combinedStyles = combineStyles(style, styles);

class SocialShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }

  renderSocialList = () => {
    const { classes, config = {} } = this.props;

    const url = config.url ? config.url : "http://sites.spinaction.com";
    const quote = config.quote ? config.quote : "Come join in on the fun!";

    //const buttonClasses = [classes.button, classes.padding].join(" ");

    return (
      <div className={classes.container}>
        <h4 className={classes.text}>SHARE</h4>
        <List className={classes.list}>
          <ListItem className={classes.item}>
            <FacebookShareButton url={url} quote={quote}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <TwitterShareButton url={url} quote={quote}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <PinterestShareButton
              url={url}
              media={"https://www.bluemarlinarcade.com/bluemarlinarcade.svg"}
              description={quote}
              quote={quote}
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <TumblrShareButton url={url} quote={quote}>
              <TumblrIcon size={32} round />
            </TumblrShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <RedditShareButton url={url} quote={quote}>
              <RedditIcon size={32} round />
            </RedditShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <LinkedinShareButton url={url} quote={quote}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </ListItem>
          <ListItem className={classes.item}>
            <EmailShareButton url={url} quote={quote}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </ListItem>
        </List>
        {this.props.children}
      </div>
    );
  };

  render() {
    return <div>{this.renderSocialList()}</div>;
  }
}

SocialShare.defaultProps = {
  active: -1,
  activeColor: "primary",
};

SocialShare.propTypes = {
  activeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
};

export default withStyles(combinedStyles)(SocialShare);
