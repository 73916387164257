import { grayColor } from "assets/jss/material-kit-pro-react.js";

const style = {
  title: {
    fontSize: "30px",
    lineHeight: 1.0,
    fontWeight: 300,
    color: "#000000",
    marginBotton: "0.5rem",
    fontFamily: "inherit",
    marginTop: 0,
    display: "block",
    paddingBottom: "10px",
  },
  divider: {
    fontSize: "24px",
    lineHeight: 5.0,
    fontWeight: 300,
    color: "#000000",
    marginBotton: "2.5rem",
  },
  content: {
    paddingBottom: "20px",
    lineHeight: 1.5,
    paddingTop: "10px",
    color: grayColor[0],
    overflow: "hidden",
    marginTop: "10px",
    fontSize: "20px",
    "& p": {
      color: grayColor[0],
      fontSize: "14px",
    },
  },
};

export default style;
