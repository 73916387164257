import {} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  welcome: {
    marginBottom: "30px",
    color: "#fff",
    fontSize: "24px",
    textTransform: "uppercase",
    letterSpacing: ".1em",
    position: "relative",
    display: "inline-block",
    fontWeight: "300",
    "&::after": {
      width: "80px",
      height: "2px",
      background: "#fff",
      position: "absolute",
      left: "110%",
      top: "50%",
      webkitTransform: "translateY(-50%)",
      msTransform: "translateY(-50%)",
      transform: "translateY(-50%)",
      content: '""',
    },
  },
});

export default style;
