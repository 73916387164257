/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// custom components
import Header from "_ext/components/Header/Header";
import HeaderLinks from "_ext/components/Header/HeaderLinks";
import BrandLogo from "_ext/components/BrandLogo/BrandLogo";
import ParallaxBanner from "_ext/components/Parallax/ParallaxBanner";

// sections for this page
import SectionOverview from "_ext/views/ArcadePage/Sections/SectionOverview";
import SectionDetail from "_ext/views/ArcadePage/Sections/SectionDetail";

import pinballAndGolfImage from "_ext/assets/img/bg_arcade_3_2400x1600.jpeg";

import style from "./style.js";

import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  page: {
    backgroundColor: "#6DB3F2",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function HomePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand={<BrandLogo />}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />

      <ParallaxBanner
        image={pinballAndGolfImage}
        //filter="dark"
        //small
        className={classes.parallax + " " + classes.page}
      >
        <div className={classes.container + " " + classes.center}>
          <GridContainer>
            <GridItem>
              <div className={classes.brandContainer}>
                <h3 className={classes.brandTitle}>Entertainment Paradise</h3>
                <h1 className={classes.brandSubTitle}>Your Personal Arcade</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </ParallaxBanner>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionOverview />
        <SectionDetail />
      </div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-presentation"
                target="_blank"
                className={classes.footerBrand}
              >
                Material Kit PRO React
              </a>
            </div>
          </div>
        }
      />
    </div>
  );
}
