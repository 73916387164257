import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// custom components
import TrailingHR from "_ext/components/Style/TrailingHR";
import ImageCard from "_ext/components/Cards/ImageCard";

import style from "./roomsStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  gridItem: {
    paddingBottom: "30px",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionRooms() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <TrailingHR color="#999999" name="makeStyles-title-150" />
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={12} sm={12}>
            <div className={classes.headingContainer}>
              <h3 className={classes.title}>Featured Rooms</h3>
              <h1 className={classes.shortDescription}>Rooms & Suites</h1>
              <div style={{ height: "40px" }} />
              <p className={classes.description}>
                All of our bedrooms have access to the deck with a beach view
                and hot tub. Most of the beds have Simmons BeautyRest pillow top
                mattresses (including the twin bunks!) Although we have a
                maximum occupancy of 10, there Sleeping arrangements accommodate
                for 12 guests in beds. All towels/linens are provided.
              </p>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem lg={4} md={6} sm={12} className={classes.gridItem}>
              <ImageCard
                title="Executive Master Suite"
                images={[
                  {
                    image: require("_ext/assets/img/img_master_1.jpg"),
                    name: "img_master_1",
                  },
                  {
                    image: require("_ext/assets/img/img_master_2.jpg"),
                    name: "img_master_2",
                  },
                  {
                    image: require("_ext/assets/img/img_master_3.jpg"),
                    name: "img_master_3",
                  },
                  {
                    image: require("_ext/assets/img/img_master_4.jpg"),
                    name: "img_master_4",
                  },
                  {
                    image: require("_ext/assets/img/img_master_5.jpg"),
                    name: "img_master_5",
                  },
                  {
                    image: require("_ext/assets/img/img_master_6.jpg"),
                    name: "img_master_6",
                  },
                  {
                    image: require("_ext/assets/img/img_master_7.jpg"),
                    name: "img_master_7",
                  },
                  {
                    image: require("_ext/assets/img/img_master_8.jpg"),
                    name: "img_master_8",
                  },
                  {
                    image: require("_ext/assets/img/img_master_9.jpg"),
                    name: "img_master_9",
                  },
                  {
                    image: require("_ext/assets/img/img_masterbath_1.jpg"),
                    name: "img_masterbath_1",
                  },
                  {
                    image: require("_ext/assets/img/img_masterbath_2.jpg"),
                    name: "img_masterbath_2",
                  },
                  {
                    image: require("_ext/assets/img/img_masterbath_3.jpg"),
                    name: "img_masterbath_3",
                  },
                  {
                    image: require("_ext/assets/img/img_masterbath_4.jpg"),
                    name: "img_masterbath_4",
                  },
                ]}
                description="Features OCEAN VIEWS from master suite; sliding door that
                opens out the the 3rd floor deck with hot tub. Private Master bath
                with jetted garden tub, double sinks & beautiful tile countertops
                and flooring."
                content={
                  <ul className={classes.listUnstyled}>
                    <li>
                      <b>Sleeps: </b>4 - King (sleeps 2) & Twindle (sleeps 2)
                    </li>
                    <li>
                      <b>Categories: </b>
                    </li>
                    <li>
                      <b>Facilities: </b>Closet with Hangers, HD Flat-Screen
                      Internet TV, DVD, Bathroom, Jetted Tub
                    </li>
                    <li>
                      <b>Size: </b>160 ft<sup>2</sup>
                    </li>
                    <li>
                      <b>Bed Type: </b>1 King - 2 Twin/Single
                    </li>
                  </ul>
                }
              />
            </GridItem>
            <GridItem lg={4} md={6} sm={12} className={classes.gridItem}>
              <ImageCard
                title="Bedroom 1"
                images={[
                  {
                    image: require("_ext/assets/img/img_bedroom1_1_sm.jpg"),
                    name: "img_bedroom1_1",
                  },
                  {
                    image: require("_ext/assets/img/img_bedroom1_2_sm.jpg"),
                    name: "img_bedroom1_2",
                  },
                  {
                    image: require("_ext/assets/img/img_bedroom1_3_sm.jpg"),
                    name: "img_bedroom1_3",
                  },
                ]}
                description={
                  <div>
                    This room has an oversized window with a view of the
                    backyard. It is the first room at the top of the the stairs.
                    Shares a hallway bathroom with the other Bedroom. This room
                    also sports a two-twin bunk bed.
                  </div>
                }
                content={
                  <ul className={classes.listUnstyled}>
                    <li>
                      <b>Sleeps: </b>4 - King (sleeps 2) & Bunk (sleeps 2)
                    </li>
                    <li>
                      <b>Categories: </b>
                    </li>
                    <li>
                      <b>Facilities: </b>Closet with Hangers, HD Flat-Screen TV
                      w/ Roku, DVD Player, Cable w/ Spectrum App
                    </li>
                    <li>
                      <b>Size: </b>100 ft<sup>2</sup>
                    </li>
                    <li>
                      <b>Bed Type: </b>1 King - 1 Bunk Bed
                    </li>
                  </ul>
                }
              />
            </GridItem>
            <GridItem lg={4} md={6} sm={12} className={classes.gridItem}>
              <ImageCard
                title="Bedroom 2"
                images={[
                  {
                    image: require("_ext/assets/img/img_bedroom2_1_sm.jpg"),
                    name: "img_bedroom2_1",
                  },
                  {
                    image: require("_ext/assets/img/img_bedroom2_2_sm.jpg"),
                    name: "img_bedroom2_2",
                  },
                  {
                    image: require("_ext/assets/img/img_bedroom2_3_sm.jpg"),
                    name: "img_bedroom2_3",
                  },
                  {
                    image: require("_ext/assets/img/img_bedroom2_4_sm.jpg"),
                    name: "img_bedroom2_4",
                  },
                ]}
                description={
                  <div>
                    Large windows with a view of the Ocean. Next to the Master
                    Suite with door exiting out to the covered 3rd-floor deck
                    with the Hot Tub. Dark shades to cut out light for
                    late-morning risers. Sports a two-twin bunk bed.
                  </div>
                }
                content={
                  <ul className={classes.listUnstyled}>
                    <li>
                      <b>Sleeps: </b>4 Queen (sleeps 2) & Bunk (sleeps 2)
                    </li>
                    <li>
                      <b>Categories: </b>
                    </li>
                    <li>
                      <b>Facilities: </b>Closet with Hangers, HD Flat-Screen TV
                      w/ Roku, DVD Player, Cable w/ Spectrum App
                    </li>
                    <li>
                      <b>Size: </b>80 ft<sup>2</sup>
                    </li>
                    <li>
                      <b>Bed Type: </b>1 Queen - 1 Bunk Bed
                    </li>
                  </ul>
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
