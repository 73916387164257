import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui icons
import StarRate from "@material-ui/icons/StarRate";
import ViewDay from "@material-ui/icons/ViewDay";
import Kitchen from "@material-ui/icons/Kitchen";
import BeachAccess from "@material-ui/icons/BeachAccess";
import RoomService from "@material-ui/icons/RoomService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// custom components
import ReviewCard from "_ext/components/Cards/ReviewCard";

import style from "./reviewsStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionReviews() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={12} sm={12}>
            <div className={classes.headingContainer}>
              <h3 className={classes.title}>Reviews</h3>
              <h1 className={classes.shortDescription}>Guest Reviews</h1>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem lg={4} md={6} sm={12}>
              <ReviewCard
                title={"Great Place to vacation!"}
                description={
                  "This is the second time we have stayed at this property. It was clean, great location, great games, good amount of pots, pans and things for cooking. The beds were comfortable and it was overall a very good experience."
                }
                name={"Denise J."}
                stayed={"Jul 2020"}
              />
            </GridItem>
            <GridItem lg={4} md={6} sm={12}>
              <ReviewCard
                title={"Wonderful family vacation spot"}
                description={
                  "Beautiful home that we felt comfortable in as soon as we stepped in the door. Lots of room to spread out and the game room was a huge hit with the kids. We will try to stay here again for future family events. Thank you."
                }
                name={"Rhonda H."}
                stayed={"Jul 2020"}
              />
            </GridItem>
            <GridItem lg={4} md={6} sm={12}>
              <ReviewCard
                title={"Great location"}
                description={
                  "Great location. Just far enough off the beach that you don’t have to worry about traffic coming by the house. Very clean home."
                }
                name={"Amber R."}
                stayed={"Jul 2020"}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
