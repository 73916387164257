import React from "react";
// 3rd Party Components
import { Parallax, Background } from "react-parallax";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import style from "./parallaxStyle.js";
import combineStyles from "_ext/services/combineStyles";

const styles = (theme) => ({
  page: {
    backgroundColor: "#6DB3F2",
  },
  brandContainer: {
    // lineHeight: "1.1",
    // marginBottom: "50px",
    // marginTop: "0",
    // boxSizing: "border-box",
    // display: "block",
    // textAlign: "left",
    // width: "70%",
  },
  center: {
    margin: 0,
    marginRight: "auto",
    marginLeft: "auto",
    right: "0",
    left: "0",
    position: "absolute",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
    //wordWrap: "break-word",
  },
  example: {
    // //position: "absolute",
    // /* center the element */
    // right: "0",
    // left: "0",
    // marginRight: "auto",
    // marginLeft: "auto",
    // /* give it dimensions */
    // minHeight: "10em",
    // width: "90%",
  },
});

const combinedStyles = combineStyles(styles, style);

const useStyles = makeStyles(combinedStyles);

export default function SectionParallax(props) {
  const classes = useStyles();
  return (
    <div>
      <Parallax
        bgImage={require("_ext/assets/img/bg_mainfloor_1_2400x1600.jpeg")}
        bgImageAlt="the cat"
        strength={500}
        renderLayer={() => (
          <div
            style={{
              position: "absolute",
              background: "rgba(0, 0, 0, 0.5)",
              left: "0%",
              top: "0%",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      >
        <div className={classes.container + " " + classes.center}>
          <GridContainer>
            <GridItem>
              <div className={classes.brandContainer}>{props.children}</div>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{ height: "800px" }} />
      </Parallax>
    </div>
  );
}
