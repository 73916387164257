import { container } from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container,
  backgroundStyle: {
    backgroundImage: "linear-gradient(to right, #0478BF, #376CC3)",
    width: "100vw",
    position: "relative",
    marginLeft: "-50vw",
    height: "100vh",
    marginTop: "100px",
    left: "50%",
  },
});

export default style;
